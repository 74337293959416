import React from 'react'
import PropTypes from 'prop-types'
import createClass from 'create-react-class'

const ReactPassword = createClass({
  propTypes: {
    onChange: PropTypes.func,
    value: PropTypes.string,
  },

  getDefaultProps() {
    return {
      onChange: null,
      value: '',
    }
  },

  getInitialState() {
    return {
      value: this.props.value || '',
    }
  },

  handleChange(e) {
    this.setState({ value: e.target.value })
    this.props.onChange(e)
  },

  handleKeyDown(e) {
    const ENTER = 13
    if (e.keyCode === ENTER) {
      this.setState({ value: e.target.value })
      this.props.onEnter(e)
    }
  },

  render() {
    return (
      <input
        type="text"
        value={this.state.value}
        onChange={this.handleChange}
        onKeyDown={this.handleKeyDown}
        placeholder="Enter password"
        className="password"
      />
    )
  },
})

export default ReactPassword
