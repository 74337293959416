import React from "react"
import PropTypes from "prop-types"

import BackgroundVideo from "../components/BackgroundVideo"

import styled, { css } from "styled-components/macro"

import pixel from "../../assets/pixel.png"

function CarouselSlide({ description: video, isSafari, fixed, index, fluid }) {
  const isVideo = (video && video.length > 3) || false

  return (
    <Slide className="swiper-slide fade-in" data-history={`${index + 1}`}>
      {isVideo ? (
        <>
          <DummyImageForVideoLoading
            data-src={pixel}
            className="swiper-lazy"
            alt=""
          />
          <BackgroundVideo src={video} />
        </>
      ) : (
        <>
          {isSafari ? (
            <img data-src={fixed.src} className="swiper-lazy" alt="" />
          ) : (
            <img
              data-srcset={fluid.srcSetWebp}
              className="swiper-lazy"
              alt=""
            />
          )}
        </>
      )}

      <div className="swiper-lazy-preloader" />
    </Slide>
  )
}

CarouselSlide.propTypes = {
  fluid: PropTypes.object,
  file: PropTypes.object,
}

export default CarouselSlide

const Slide = styled.div(
  () => css`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;

    .swiper-lazy-preloader {
      border-bottom-color: #d8d8d8 !important;
      border-left-color: #d8d8d8 !important;
      border-right-color: #d8d8d8 !important;
      border-top-color: rgba(255, 255, 255, 0.2) !important;
      border: 1px solid;
    }

    img {
      object-fit: contain;
    }

    img,
    iframe {
      height: 100%;
      width: 100%;
      max-height: 100%;
      outline: none;
      border: none;
    }
  `
)

const DummyImageForVideoLoading = styled.img`
  display: none;
`
