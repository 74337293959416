import { useState, useEffect, useCallback } from 'react'

export default function(targetKey) {
  const [keyPressed, setKeyPressed] = useState(false)

  const handleKeyDown = useCallback(
    ({ which }) => {
      if (which === targetKey) setKeyPressed(true)
    },
    [targetKey],
  )

  const handleKeyUp = useCallback(
    ({ which }) => {
      if (which === targetKey) setKeyPressed(false)
    },
    [targetKey],
  )

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown, false)
    window.addEventListener('keyup', handleKeyUp, false)

    return () => {
      window.removeEventListener('keydown', handleKeyDown)
      window.removeEventListener('keyup', handleKeyUp)
    }
  }, [handleKeyDown, handleKeyUp])

  return keyPressed
}
