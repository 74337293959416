import React from "react"

import ProjectDetail from "../components/pages/ProjectDetail"
import SEO from "../components/SeoMeta"

import { get, keyBy } from "lodash"
import { graphql } from "gatsby"

const PageTemplate = ({ data, pageContext, ...props }) => {
  const { siteMetadata } = data.site
  const sets = get(data, "allContentfulGallery.nodes")
  const keys = keyBy(sets, "contentfulid")
  const project = get(keys, pageContext.pagePath)
  const title = project.title
  const seoImage = project.featuredImage.fixed

  return (
    <>
      <SEO {...siteMetadata} image={seoImage} title={`Detail - ${title}`} />
      <ProjectDetail {...props} {...project} />
    </>
  )
}

export const query = graphql`
  query DetailPageQuery {
    allContentfulGallery {
      nodes {
        title
        contentfulid
        imagesLayoutType
        articleLayout {
          title
          rightImagePercent
          videoUrl
          body {
            json
          }
          images {
            fluid(maxWidth: 2400) {
              srcWebp
              srcSetWebp
              src
              sizes
              srcSet
              aspectRatio
            }
          }
          type
        }
        featuredImage {
          id
          title
          description
          fixed(width: 2400, height: 2400) {
            height
            src
            width
          }
          fluid(maxWidth: 2400, maxHeight: 2400) {
            srcWebp
            srcSetWebp
            src
            sizes
            srcSet
            aspectRatio
          }
        }
        images {
          id
          title
          description
          file {
            details {
              image {
                height
                width
              }
            }
          }
          fixed(width: 2400) {
            height
            src
            width
          }
          fluid(maxWidth: 2400) {
            srcWebp
            srcSetWebp
            src
            sizes
            srcSet
            aspectRatio
          }
        }
      }
    }
    site {
      siteMetadata {
        title
      }
    }
  }
`

export default PageTemplate
