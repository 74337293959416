import React from "react"
import ReactPassword from "../components/ReactPassword"

import { isNull } from "lodash"

const SecretMixin = {
  componentWillMount() {
    if (typeof window !== "undefined") {
      this.getSecret()
    } else {
      this.setState({ showPassword: false })
    }
  },

  getSecret() {
    const localSecret = localStorage.getItem(this.props.id)
    const showPassword = isNull(localSecret)
    this.setState({ showPassword })
  },

  onPasswordChange() {
    this.checkSecret()
  },

  onEnter() {
    this.checkSecret(false)
  },

  checkSecret(typing = true) {
    if (typeof window === "undefined") return

    const { id, password } = this.props
    const secret = typing ? password.substr(0, password.length - 1) : password
    if (this.password.state.value === secret) {
      localStorage.setItem(id, true)
      this.setState({ showPassword: false })
    }
  },

  secretInput() {
    return (
      <div className="page secret page-top-margin">
        <div className="secret-container">
          <ReactPassword
            ref={i => (this.password = i)}
            revealed={this.state.checked}
            maxLength="25"
            onChange={this.onPasswordChange}
            onEnter={this.onEnter}
            id="secret-password"
          />
        </div>
      </div>
    )
  },
}

export default SecretMixin
