import React, { useRef, useEffect } from "react"

import Swiper from "swiper"
import { Link, navigate } from "gatsby"
import styled, { css } from "styled-components/macro"
import useWindowSize from "../../hooks/useWindowSize"
import keyCodes from "../../constants/keyCodes"
import useKeyPress from "../../hooks/useKeyPress"
import useSSR from "use-ssr"
import useIsSafari from "../../hooks/useIsSafari"
import { isUndefined } from "lodash"
import CarouselSlide from "./CarouselSlide"

import "swiper/css/swiper.css"

const PAD = 134
const PAD_SM = 114

function CarouselSwiper({
  id,
  path,
  pathname,
  images,
  num: initialSlide,
  ...props
}) {
  const { isBrowser } = useSSR()
  const isSafari = useIsSafari()
  const targetRef = useRef()
  const swiperRef = useRef()
  const previousRef = useRef()
  const paginationRef = useRef()
  const nextRef = useRef()
  const { height: vh } = useWindowSize()
  const escPressed = useKeyPress(keyCodes.ESC)
  const setURL = `/set/${pathname[1]}/${id}`

  const ready = isBrowser && !isUndefined(isSafari)

  useEffect(() => {
    if (escPressed) navigate(setURL)
  }, [escPressed, setURL])

  useEffect(() => {
    if (ready && !swiperRef.current) {
      swiperRef.current = new Swiper(targetRef.current, {
        //cssMode: true,
        loop: true,
        initialSlide,
        grabCursor: true,
        navigation: {
          nextEl: nextRef.current,
          prevEl: previousRef.current,
        },
        pagination: {
          el: paginationRef.current,
          type: "fraction",
        },
        keyboard: {
          enabled: true,
          onlyInViewport: false,
        },
        mousewheel: {
          invert: true,
        },
        history: {
          key: id,
        },
        preloadImages: false,
        lazy: {
          loadPrevNext: true,
          loadPrevNextAmount: 2,
        },
      })
    }

    return () => {
      if (swiperRef.current) swiperRef.current.destroy()
    }
  }, [targetRef, swiperRef, initialSlide, ready, id])

  const goBackWithIndex = () => {
    navigate(setURL, { state: { index: swiperRef.current?.realIndex || 0 } })
  }

  return (
    <LayoutContainer>
      <Nav className="fs-nav">
        <div className="fs-nav-row fs-nav-high">
          <Link className="fs-btn fs-mark" to="/">
            <svg className="ja-grey" x="0px" y="0px" viewBox="-289 385 32 23">
              <polygon
                className="ja-grey-shape"
                points="-271,385 -271,385 -271,385 -279.9,400.2 -279.9,400.2 -288.9,400.2 -284.4,407.9 -275.5,407.9 -271,400.3 -266.5,407.9 -257.6,407.9 "
              />
            </svg>
          </Link>

          <CloseButton className="fs-btn fs-close " onClick={goBackWithIndex}>
            <svg
              className="close-svg"
              width="22px"
              height="22px"
              viewBox="0 0 22 22"
            >
              <g transform="translate(1.000000, 1.000000)">
                <path
                  className="close-line"
                  d="M0.347826087,19.5454545 L19.7391304,0.454545455"
                />
                <path
                  className="close-line"
                  d="M19.6521739,19.5454545 L0.260869565,0.454545455"
                />
              </g>
            </svg>
          </CloseButton>
        </div>
      </Nav>
      <Container ref={targetRef} style={{ height: vh }}>
        <Content className="swiper-wrapper" vh={vh}>
          {ready &&
            images.map((item, i) => {
              return (
                <CarouselSlide
                  key={`img${i}`}
                  index={i}
                  isSafari={isSafari}
                  {...item}
                />
              )
            })}
        </Content>
      </Container>
      <Nav className="fs-nav">
        <div className="fs-nav-row fs-nav-low">
          <div ref={previousRef} className="fs-btn prev-btn">
            <svg width="23px" height="27px" viewBox="0 0 23 27">
              <g stroke="none" fill="none" fillRule="evenodd">
                <path
                  d="M21,13.5 L1,2 L1,25 L21,13.5 L21,13.5 Z"
                  className="arrow-path"
                />
              </g>
            </svg>
          </div>

          <div className="slide-count" ref={paginationRef} />

          <div ref={nextRef} className="fs-btn next-btn">
            <svg width="23px" height="27px" viewBox="0 0 23 27">
              <g stroke="none" fill="none" fillRule="evenodd">
                <path
                  d="M21,13.5 L1,2 L1,25 L21,13.5 L21,13.5 Z"
                  className="arrow-path"
                />
              </g>
            </svg>
          </div>
        </div>
      </Nav>
    </LayoutContainer>
  )
}

export default CarouselSwiper

const LayoutContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100vw;
  flex: 1;
  height: 100%;
  background: #2a2a2a;
`

const Nav = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
`

const Container = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  flex: 1;
  height: 100%;
`

const Content = styled.div(
  ({ vh }) => css`
    height: ${vh - PAD_SM}px;

    @media screen (min-width: 481px) {
      height: ${vh - PAD}px;
    }
  `
)

const CloseButton = styled.button`
  border: none;
  margin: 0;
  padding: 0;
  width: auto;
  overflow: visible;
  background: transparent;
`
