import React from "react"
import parseVideoURL from "../utils/parseVideoURL"

export default function BackgroundVideo({ src }) {
  const { type, id } = parseVideoURL(src)

  return (
    <>
      {type === "youtube" && (
        <iframe
          id="ytplayer"
          type="text/html"
          width="640"
          height="360"
          src={`https://www.youtube.com/embed/${id}`}
          frameBorder="0"
          title="vid"
        />
      )}
      {type === "vimeo" && (
        <iframe
          src={`https://player.vimeo.com/video/${id}`}
          width="640"
          height="360"
          frameBorder="0"
          // webkitAllowFullScreen
          // mozAllowFullScreen
          allowFullScreen
          title="vid-2"
        />
      )}
    </>
  )
}
