import React, { useEffect } from "react"

import createClass from "create-react-class"

import SecretMixin from "../mixins/SecretMixin.js"
import CarouselSwiper from "../components/CarouselSwiper"

import useSSR from "use-ssr"
import { last } from "lodash"

const ProjectDetail = ({ location, history, ...props }) => {
  const { isBrowser } = useSSR()

  const pathname = location.pathname
    .split("/")
    .filter(path => path !== "" && path !== "set")

  const path = pathname[1]
  const id = pathname[2]
  const num = Number(last(pathname))

  useEffect(() => {
    let el
    if (isBrowser) {
      el = document.getElementById("gatsby-focus-wrapper")
      el.classList.add("flex-col")
    }

    return () => {
      if (isBrowser && el) el.classList.remove("flex-col")
    }
  }, [isBrowser])

  return isBrowser ? (
    <ProjectDetailDisplay
      key={location.pathname}
      path={path}
      id={id}
      num={num}
      pathname={pathname}
      //pages={pages}
      location={location}
      {...props}
    />
  ) : null
}

export default ProjectDetail

const ProjectDetailDisplay = createClass({
  mixins: [SecretMixin],

  // componentWillMount() {
  //   this.setState({ num: Number(this.props.id) })
  // },

  // componentWillReceiveProps(nextProps) {
  //   const { location } = nextProps
  //   const paths = location.pathname.split("/").filter(path => path !== "")
  //   this.setState({ num: Number(last(paths)) })
  // },

  render() {
    const { id, path, images, num, pathname } = this.props

    if (this.state.showPassword && this.state.secret) {
      return this.secretInput()
    } else {
      return (
        <div className="project-detail">
          <CarouselSwiper
            images={images}
            pathname={pathname}
            path={path}
            id={id}
            num={num - 1}
          />
        </div>
      )
    }
  },
})
